<template>
  <v-container class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      temporary
      class="hidden-md-and-up pa-0"
      app
      width="400px"
      min-width="400px"
      mobile-breakpoint="960"
      tile
      height="100%"
      color="greenBg"
    >
      <v-btn absolute icon x-large color="primary" @click="drawer = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-img
        :src="require('@/assets/islacoop/logo-horizontal.png')"
        max-width="280px"
        class="my-4 mx-auto"
        contain
      >
      </v-img>

      <v-list nav>
        <div v-for="(item, i) in navFiltered" :key="i">
          <v-list-item
            link
            :to="paths[item.id_name]"
            exact
            color="primary"
            v-if="item.children.length == 0"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ icons[item.id_name] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary">{{ icons[item.id_name] }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, j) in item.children"
              :key="j"
              link
              :to="{
                path:
                  paths[item.id_name] +
                  (paths[child.id_name] ? paths[child.id_name] : child.id_name),
              }"
              exact
            >
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary">{{
                  icons[child.id_name] ? icons[child.id_name] : icons.default
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-4">
          <v-btn
            elevation="5"
            dark
            block
            x-large
            color="greenButtonTitle"
            :href="url"
            target="_blank"
            :ripple="false"
            >Cooperativa en línea</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import infoJson from "@/assets/json/general.json";

export default {
  data() {
    return {
      paths: infoJson.paths,
      icons: infoJson.icons,
    };
  },
  computed: {
    ...mapState(["nav"]),
    ...mapGetters({
      drawerTest: "drawer",
    }),
    drawer: {
      get() {
        return this.drawerTest;
      },
      set(state) {
        this.switchDrawer(state);
        return state;
      },
    },
    url() {
      return process.env.VUE_APP_URL_PORTAL;
    },
    navFiltered() {
      let navCopy = JSON.parse(JSON.stringify(this.nav));
      navCopy = navCopy.map((item) => {
        if (item.orderTop == 0) item.orderTop = 10;
        return item;
      });
      return navCopy.sort((a, b) => a.orderTop - b.orderTop);
    },
  },

  methods: {
    ...mapActions(["switchDrawer"]),
  },
};
</script>
<style scoped lang="css">
.glass {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.573);
  backdrop-filter: blur(10px);
}
.transparente {
  background-color: transparent;
}

.nombre {
  white-space: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}
</style>
