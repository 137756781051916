import Vue from "vue";
import Vuex from "vuex";
import contenido from "@/services/contenido";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
    snackbar: {
      mostrar: false,
      mensaje: "",
      color: "success",
      icon: "",
    },
    socialMedia: [],
    nav: [],
    loadingNav: true,
    loadingSocial: true,
    loadingTextos: true,
    textos: [],
  },
  getters: {
    drawer: (state) => {
      return state.drawer;
    },
  },
  mutations: {
    SET_DRAWER(state, value) {
      state.drawer = value;
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
    CLOSE_SNACKBAR(state) {
      state.snackbar.mostrar = false;
    },
    SET_TEXTOS(state, payload) {
      state.textos = payload;
    },
    SET_SOCIAL(state, payload) {
      state.socialMedia = payload;
    },
    SET_NAV(state, payload) {
      state.nav = payload;
    },
    SET_LOADNAV(state, payload) {
      state.loadingNav = payload;
    },
    SET_LOADSOCIAL(state, payload) {
      state.loadingSocial = payload;
    },
    SET_LOADTEXT(state, payload) {
      state.loadingTextos = payload;
    },
  },
  actions: {
    switchDrawer({ commit }, value) {
      commit("SET_DRAWER", value);
    },
    mostrarAlerta({ commit }, payload) {
      if (payload.color === "success") {
        payload.icon = "mdi-check-circle-outline";
      } else if (payload.color === "warning") {
        payload.icon = "mdi-alert-outline";
      } else if (payload.color === "error") {
        payload.icon = "mdi-alert-octagon-outline";
      } else if (payload.color === "info") {
        payload.icon = "alert-circle-outline";
      }

      commit("SET_SNACKBAR", {
        mostrar: payload.mostrar,
        color: payload.color,
        mensaje: payload.mensaje,
        icon: payload.icon,
      });
    },
    cerrarAlerta({ commit }) {
      commit("CLOSE_SNACKBAR");
    },
    async obtenerTextos({ commit }) {
      commit("SET_LOADTEXT", true);
      await contenido
        .obtenerTextos()
        .then((res) => {
          commit("SET_TEXTOS", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      commit("SET_LOADTEXT", false);
    },
    async obtenerSocialMedia({ commit }) {
      commit("SET_LOADSOCIAL", true);
      await contenido
        .obtenerRedesSociales()
        .then((res) => {
          commit("SET_SOCIAL", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      commit("SET_LOADSOCIAL", false);
    },
    async obtenerNav({ commit }) {
      commit("SET_LOADNAV", true);
      await contenido
        .obtenerSecciones()
        .then((res) => {
          commit("SET_NAV", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      commit("SET_LOADNAV", false);
    },
  },
  modules: {},
});
