<template>
  <v-container class="my-2">
    <v-row class="text-center" no-gutters justify="center">
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="mb-2">
        <v-img
          contain
          :src="require('@/assets/islacoop/logo-horizontal.png')"
          @click="$router.push('/')"
          style="cursor: pointer"
        >
        </v-img>
      </v-col>
      <v-col cols="12">
        <v-toolbar color="transparent" flat>
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            contain
            width="0"
            height="80"
            :src="require('@/assets/islacoop/logo-horizontal.png')"
            @click="$router.push('/')"
            style="cursor: pointer"
          >
          </v-img>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <div
            class="align-self-center"
            :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto' }"
          >
            <!-- <v-btn elevation="5" dark class="mx-2" x-large color="greenButtonTitle"
              >¡Paga sin clave!</v-btn
            > -->
            <v-hover v-slot="{ hover }">
              <v-btn
                elevation="5"
                dark
                class="mx-2"
                v-bind="large"
                color="greenButtonTitle"
                :href="url"
                target="_blank"
                :ripple="false"
                :style="{ transform: hover ? 'scale(1.06)' : 'scale(1)' }"
                >Cooperativa en línea</v-btn
              >
            </v-hover>
          </div>
        </v-toolbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    large() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return { large: true };
        case "xs":
          return {};
        default:
          return { "x-large": true };
      }
    },
    url() {
      return process.env.VUE_APP_URL_PORTAL;
    },
  },
};
</script>
