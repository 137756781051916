import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "inicio",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: () => import("../views/CompanyView.vue"),
    children: [
      {
        path: "directorio",
        name: "directorio",
        component: () => import("../views/company/DirectoryView.vue"),
      },
      {
        path: "junta-general-anual",
        name: "juntaGral",
        component: () => import("../views/company/GeneralMeetView.vue"),
      },
    ],
  },
  {
    path: "/productos",
    name: "productos",
    component: () => import("../views/ProductsView.vue"),
    children: [
      {
        path: ":product/",
        name: "producto",
        component: () => import("../views/products/ProductView.vue"),
        children: [
          {
            path: ":subproduct",
            name: "subproducto",
            component: () => import("../components/products/ProductInfo.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/informacion-corporativa",
    name: "infCor",
    component: () => import("../views/FinancialInfoView.vue"),
  },
  {
    path: "/beneficios",
    name: "beneficios",
    component: () => import("../views/BenefitsView.vue"),
  },
  {
    path: "/portabilidad-financiera",
    name: "portFin",
    component: () => import("../views/FinancialPortView.vue"),
  },
  {
    path: "/preguntas-frecuentes",
    name: "pregFrec",
    component: () => import("../views/QAView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (/*to, from, savedPosition*/) => {
    //if (to.hash) return { selector: to.hash };
    //if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (store.state.loadingNav) store.dispatch("obtenerNav");

  if (store.state.loadingSocial) store.dispatch("obtenerSocialMedia");

  if (store.state.loadingTextos) store.dispatch("obtenerTextos");

  next();
});

export default router;
