import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import es from "vuetify/lib/locale/es";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#34A693",
        secondary: "#424242",
        primaryDk1: colors.teal.darken1,
        greenDivider: colors.teal.darken1,
        greenBg: colors.teal.lighten5,
        greenButtonTitle: colors.teal.accent4,
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },

  lang: {
    locales: { es },
    current: "es",
  },
});
