<template>
  <v-container class="pa-0" v-scroll="absolute">
    <v-app-bar app light color="greenBg" inverted-scroll>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="switchDrawer(true)"
      />
      <v-speed-dial
        v-model="fab"
        top
        left
        direction="bottom"
        transition="slide-y-reverse-transition"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <template v-slot:activator>
          <v-btn icon x-large color="primary">
            <v-icon large> mdi-web </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="(social, i) in socialFiltered"
          :key="i"
          fab
          small
          target="_blank"
          :href="social.link"
          color="greenBg"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon :color="social.color" size="25" v-bind="attrs" v-on="on"
                >mdi-{{ social.icon }}</v-icon
              >
            </template>
            <span>{{ social.name }}</span>
          </v-tooltip>
        </v-btn>
      </v-speed-dial>
      <div v-else>
        <v-btn
          v-for="(social, i) in socialFiltered"
          :key="i"
          icon
          tile
          target="_blank"
          :href="social.link"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="25" v-bind="attrs" v-on="on"
                >mdi-{{ social.icon }}</v-icon
              >
            </template>
            <span>{{ social.name }}</span>
          </v-tooltip>
        </v-btn>
      </div>
      <v-img
        v-if="$vuetify.breakpoint.mdAndUp"
        contain
        width="130"
        height="60"
        class="ml-8"
        :src="require('@/assets/islacoop/logo-horizontal.png')"
        @click="$router.push('/')"
        style="cursor: pointer"
      >
      </v-img>

      <v-tabs centered optional class="hidden-sm-and-down">
        <template v-if="loadingNav">
          <v-tab v-for="j in 5" :key="`skl-tab-${j}`">
            <v-skeleton-loader type="table-cell" light></v-skeleton-loader>
          </v-tab>
        </template>
        <template v-else>
          <template v-for="(nav, i) in navFiltered">
            <v-tab :id="`${nav.name}-${i}`" :key="i" :to="paths[nav.id_name]">
              {{ nav.name }}
            </v-tab>
            <v-menu
              v-if="nav.children.length > 0"
              open-on-hover
              :key="nav.name"
              max-width="350"
              close-delay="500"
              offset-y
              rounded="0"
              :activator="`#${nav.name}-${i}`"
              transition="slide-x-transition"
            >
              <v-card tile>
                <v-list dense color="teal darken-1" dark>
                  <v-list-item
                    v-for="(child, i) in filterMenu(nav.children)"
                    :key="`${child.name}--${i}`"
                    link
                    exact
                    :to="{
                      path:
                        paths[nav.id_name] +
                        (paths[child.id_name]
                          ? paths[child.id_name]
                          : child.id_name),
                    }"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ child.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
        </template>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn
        elevation="5"
        dark
        class="mx-2 hidden-xs-only"
        color="greenButtonTitle"
        :href="url"
        target="_blank"
        :ripple="false"
        >Cooperativa en línea</v-btn
      >
    </v-app-bar>
    <v-scroll-y-transition>
      <v-app-bar app v-if="app" color="primary" dark>
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="switchDrawer(true)"
        />
        <template v-if="loadingSocial">
          <v-skeleton-loader
            type="avatar"
            light
            v-for="j in 4"
            :key="`skl-social-${j}`"
            class="pr-3"
          />
        </template>
        <template v-else>
          <v-btn
            v-for="(social, i) in socialFiltered"
            :key="i"
            icon
            tile
            target="_blank"
            :href="social.link"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="25" v-bind="attrs" v-on="on"
                  >mdi-{{ social.icon }}</v-icon
                >
              </template>
              <span>{{ social.name }}</span>
            </v-tooltip>
          </v-btn>
        </template>

        <v-tabs
          centered
          dark
          background-color="primary"
          optional
          class="hidden-sm-and-down"
        >
          <template v-if="loadingNav">
            <v-tab v-for="j in 5" :key="`skl-tab-${j}`">
              <v-skeleton-loader type="table-cell" light></v-skeleton-loader>
            </v-tab>
          </template>
          <template v-else>
            <template v-for="(nav, i) in navFiltered">
              <v-tab
                :id="`${nav.name}--${i}`"
                :key="i"
                :to="paths[nav.id_name]"
              >
                {{ nav.name }}
              </v-tab>
              <v-menu
                v-if="nav.children.length > 0"
                :activator="`#${nav.name}--${i}`"
                open-on-hover
                close-delay="500"
                :key="nav.name + '--' + i"
                max-width="350"
                offset-y
                rounded="0"
                transition="slide-x-transition"
              >
                <v-card tile>
                  <v-list dense color="teal darken-1" dark>
                    <v-list-item
                      v-for="(child, i) in filterMenu(nav.children)"
                      :key="`${child.name}--${i}`"
                      link
                      exact
                      :to="{
                        path:
                          paths[nav.id_name] +
                          (paths[child.id_name]
                            ? paths[child.id_name]
                            : child.id_name),
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ child.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </template>
          </template>
        </v-tabs>
      </v-app-bar>
    </v-scroll-y-transition>
  </v-container>
</template>

<script>
import infoJson from "@/assets/json/general.json";
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      app: true,
      fab: false,
      menu: false,
      menuItem: null,
      hoverTab: null,
      paths: infoJson.paths,
    };
  },
  methods: {
    ...mapActions(["switchDrawer"]),
    absolute() {
      if (window.scrollY >= 56) this.app = false;
      else this.app = true;
    },
    filterMenu(items) {
      return items.filter((item) => !item.sidebar);
    },
  },
  computed: {
    ...mapState(["socialMedia", "nav", "loadingNav", "loadingSocial"]),
    url() {
      return process.env.VUE_APP_URL_PORTAL;
    },
    socialFiltered() {
      let preSocial = this.socialMedia.filter((social) => social.orderTop > 0);
      return preSocial.sort((a, b) => a.orderTop - b.orderTop);
    },
    navFiltered() {
      let preSection = this.nav.filter((item) => item.orderTop > 0);
      return preSection.sort((a, b) => a.orderTop - b.orderTop);
    },
  },
};
</script>
<style scoped>
#create .v-speed-dial {
  position: sticky;
}
a {
  text-decoration: none;
}
</style>
