<template>
  <v-footer padless class="justify-center" color="transparent">
    <v-card flat tile class="text-center" width="100%">
      <v-row justify="center" no-gutters>
        <template v-if="loadingSocial">
          <v-skeleton-loader
            type="table-cell"
            light
            v-for="j in 6"
            :key="`skl-navFoot-${j}`"
            class="pr-3"
          />
        </template>
        <template v-else>
          <div v-for="(nav, i) in navFiltered" :key="i">
            <v-btn
              text
              class="my-1"
              :to="{ name: nav.id_name }"
              :exact="true"
              :ripple="false"
              active-class="no-active"
            >
              <span>{{ nav.name }}</span>
            </v-btn>
          </div>
        </template>
      </v-row>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <template v-if="loadingSocial">
          <div class="d-flex justify-center">
            <v-skeleton-loader
              type="avatar"
              light
              class="mx-2"
              v-for="j in 4"
              :key="`skl-socialFoot-${j}`"
            />
          </div>
        </template>
        <template v-else>
          <v-btn
            v-for="(social, i) in socialFiltered"
            :key="i"
            active-class="no-active"
            class="mx-4 black--text"
            icon
            tile
            target="_blank"
            :href="social.link"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24px" v-bind="attrs" v-on="on">
                  mdi-{{ social.icon }}
                </v-icon>
              </template>
              <span>{{ social.name }}</span>
            </v-tooltip>
          </v-btn>
        </template>
      </v-card-text>
      <div>
        <div class="subtext mt-4">Islacoop</div>
        <div class="subsubtext my-4">contacto@islacoop.cl</div>
      </div>

      <v-divider></v-divider>

      <v-card-text class="pa-1">
        {{ new Date().getFullYear() }} — <strong>Maximiza</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["socialMedia", "nav", "loadingNav", "loadingSocial"]),
    socialFiltered() {
      let preSocial = this.socialMedia.filter(
        (social) => social.orderBottom > 0
      );
      return preSocial.sort((a, b) => a.orderBottom - b.orderBottom);
    },
    navFiltered() {
      let preSection = this.nav.filter((social) => social.orderBottom > 0);
      return preSection.sort((a, b) => a.orderBottom - b.orderBottom);
    },
  },
};
</script>

<style scoped>
.v-btn--active.no-active::before {
  display: none;
}
.subtext {
  font-size: 3.5vh;
  font-weight: 300;
}
.subsubtext {
  font-size: 2.5vh;
  font-weight: 300;
}
</style>
