<template>
  <v-app id="create">
    <v-container fluid class="pa-0">
      <app-bar />
      <app-drawer />

      <v-snackbar
        :timeout="5000"
        v-model="snackbar.mostrar"
        app
        centered
        top
        elevation="10"
        light
        style="z-index: 99 !important; padding: 0 !important"
      >
        <v-alert outlined :type="snackbar.color" prominent class="mb-0">
          {{ snackbar.mensaje }}
          <template v-slot:append>
            <v-btn icon class="ml-2" @click="cerrarAlerta()">
              <v-icon :color="snackbar.color">mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </v-alert>
      </v-snackbar>
      <v-main>
        <app-header />
        <router-view />
      </v-main>

      <app-footer />
    </v-container>
    <v-fab-transition>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-model="fab"
            color="#25D366"
            dark
            fab
            fixed
            bottom
            right
            target="_blank"
            href="https://api.whatsapp.com/send?phone=56939445824"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-whatsapp </v-icon>
          </v-btn>
        </template>
        <span>Whatsapp</span>
      </v-tooltip>
    </v-fab-transition>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppBarVue from "./components/app/AppBar.vue";
import AppDrawerVue from "./components/app/AppDrawer.vue";
import AppFooterVue from "./components/app/AppFooter.vue";
import HomeHeaderVue from "./components/home/HomeHeader.vue";

export default {
  name: "App",
  metaInfo: {
    title: "Web",
    titleTemplate: "%s | Islacoop",
    htmlAttrs: { lang: "es" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    "app-bar": AppBarVue,
    "app-footer": AppFooterVue,
    "app-drawer": AppDrawerVue,
    "app-header": HomeHeaderVue,
  },
  data: () => ({
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
  }),
  methods: {
    ...mapActions(["mostrarAlerta", "cerrarAlerta"]),
  },
  computed: {
    ...mapState(["snackbar"]),
    primaryDk1() {
      return this.$vuetify.theme.currentTheme.greenDivider;
    },
  },
  mounted() {},
};
</script>
<style>
.ql-editor {
  padding: 0px !important;
}
.divider {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.divider:last-child {
  border-right: 0;
}
.dotDivider {
  border-bottom: 5px dotted v-bind(primaryDk1);
}
.generalText {
  font-weight: 300;
  font-size: 2.6vh;
  line-height: 4.5vh;
  white-space: pre-line;
  text-align: justify;
}
.raleway {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}
.lato {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}
.playfair {
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
.v-slide-group__prev {
  display: none !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
.v-btn--active.no-active:not(:hover)::before {
  opacity: 0 !important;
}
#create .v-speed-dial {
  position: fixed;
}

#create .v-btn--floating {
  position: relative;
}
.v-snack__content {
  padding: 0 !important;
}
.v-snack__action {
  display: none !important;
}
.icon-text p {
  margin-bottom: 0px !important;
}
.loader-carrusel-md .v-skeleton-loader__image {
  height: 55vh !important;
}
.loader-carrusel-sm .v-skeleton-loader__image {
  height: "auto" !important;
}
.loader-avatar .v-skeleton-loader__avatar {
  width: 90%;
  height: 290px;
}
.loader-benefit .v-skeleton-loader__image {
  height: 325px !important;
}
.skl-center {
  text-align: -webkit-center !important;
}
.skl-transparent .v-skeleton-loader__list-item-avatar {
  background-color: transparent !important;
}
.image-trans .v-image__image {
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}
.image-trans .v-image__image,
.v-image__placeholder {
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}
.v-dialog {
  margin: 0px !important;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 98% !important;
}
</style>
