import axios from "axios";
const baseUrl = process.env.VUE_APP_API_CONTENIDO;
const api = axios.create({
  baseURL: baseUrl + "/web/",
});

export default {
  obtenerArchivos(id_name) {
    return api.get(`byIdName/${id_name}`);
  },
  obtenerTextos() {
    return api.get(`webText/`);
  },

  obtenerNoticias() {
    return api.get(`webNews/`);
  },

  obtenerInfoContacto() {
    return api.get(`infoWeb/`);
  },
  obtenerEncabezados(id_name) {
    return api.get(`headers/${id_name}`);
  },
  obtenerMiembros(id_name) {
    return api.get(`webMembers/${id_name}`);
  },
  obtenerProductos(id_name) {
    return api.get(`webProducts/${id_name}`);
  },
  obtenerProducto(name, id_name) {
    return api.get(`getProductByNameWeb/${name}/${id_name}`);
  },
  obtenerSubProducto(name, sub) {
    return api.get(`getSubProductByNameWeb/${name}/${sub}`);
  },
  obtenerSubProductos(id, notId) {
    if (notId) {
      return api.get(`getSubProductsWeb/${id}/${notId}`);
    } else return api.get(`getSubProductsWeb/${id}`);
  },
  obtenerRedesSociales() {
    return api.get(`getSocialsWeb/`);
  },
  obtenerSecciones() {
    return api.get(`getSeccionesWeb/`);
  },
};
